<template>
  <div class="container">
    <div class="list-group">
      <div id="selections-info" class="list-group-item">
        <div
          v-if="$adminPreview"
          class="custom-control custom-control-right custom-switch text-right mr-2"
        >
          <input
            id="previewToggle"
            :checked="previewMode"
            class="custom-control-input"
            type="checkbox"
            data-cy="preview-toggle"
            @click="togglePreviewMode"
          />
          <label class="custom-control-label text-info" for="previewToggle"
            >Preview Unpublished</label
          >
        </div>
        <h1 class="h3 text-center mt-2">
          Tuition Calculator
        </h1>
        <p class="text-center">
          Make selections below to see a breakdown of tuition and fees by
          semester for your college/program, academic career level, and
          residency.
        </p>
      </div>
      <SlideXRightTransition id="selections-dropdowns" group tag="div">
        <div
          v-for="dropdown in dropdowns"
          :key="dropdown.label"
          class="list-group-item"
        >
          <Dropdown :id="dropdown.id" :key="dropdown.label" v-bind="dropdown" />
        </div>
      </SlideXRightTransition>
      <div
        id="selections-controls"
        class="list-group-item d-flex justify-content-end"
      >
        <button
          :disabled="!results"
          class="btn btn-primary mx-1"
          data-cy="results-btn"
          @click="showResults"
        >
          Results
        </button>
        <button
          class="btn btn-outline-accent mx-1"
          data-cy="reset-btn"
          @click="reset"
        >
          Reset
        </button>
      </div>
    </div>
    <div
      v-if="note"
      id="note"
      class="card my-4 p-3"
      v-html="note"
      data-cy="note"
    />
  </div>
</template>

<script>
import { SlideXRightTransition } from "vue2-transitions"
import { mapState, mapActions } from "vuex"
import Dropdown from "../components/Dropdown.vue"

export default {
  components: {
    Dropdown,
    SlideXRightTransition,
  },
  data: function() {
    return {
      note: null,
    }
  },
  computed: {
    ...mapState(["dropdowns", "results", "previewMode"]),
  },
  mounted() {
    this.getNote()
  },
  /** Load data on a fresh page */
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query[0]) {
        vm.$store.dispatch("loadQuerySelections")
      } else {
        vm.$store.dispatch("fetchTerms")
      }
    })
  },
  methods: {
    ...mapActions(["togglePreviewMode"]),

    /** Provide path to results page which preserves the current query string */
    showResults() {
      this.$router.push({
        path: "results",
        query: this.$route.query,
      })
      window.scrollTo(0, 0)
    },
    reset() {
      this.$store.dispatch("reset")
      window.scrollTo(0, 0)
    },
    async getNote() {
      const [error, response] = await this.$api.get(`/note/`)
      if (error) {
        console.error(error)
      } else if (response.data) {
        this.note = response.data.note
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
/* 
We have to modify the bootstrap styles because
we're not able to make a continuous list-group
*/
$inner-border: 1px solid rgba(0, 0, 0, 0.075);
.flat-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: -1px;
}
.flat-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}

div.custom-control-right {
  padding-right: 24px;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
}
div.custom-control-right .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
div.custom-control-right .custom-control-label::before {
  right: -2.35rem;
  left: auto;
}

#selections-dropdowns .list-group-item {
  border-top: $inner-border;
  border-bottom: $inner-border;
  padding: 0.5rem 1.25rem 0.3rem 1.25rem;

  &:first-child,
  &:last-child {
    @extend .flat-top;
    @extend .flat-bottom;
  }
}
#selections-info {
  @extend .flat-bottom;
  border-bottom: none;
}
#selections-controls {
  @extend .flat-top;
  border-top: $inner-border;
  padding: 1rem;
}

#note p {
  margin-bottom: 0;
}
</style>
