<template>
  <div class="form-group">
    <div>
      <label :for="id">Select {{ label }}:</label>
    </div>
    <select
      :id="id"
      v-model="selection"
      class="custom-select w-100"
      data-cy="dropdown"
      :name="id"
    >
      <option disabled selected value hidden />
      <option
        v-for="{ value, label } in options"
        :key="value"
        :value="{ id, value }"
      >
        {{ label }}
      </option>
    </select>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
export default {
  /** Center new dropdowns on the screen */
  mounted() {
    const elementRect = this.$el.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const middle = absoluteElementTop - window.innerHeight / 2
    window.scrollTo({ left: 0, top: middle, behavior: "smooth" })
  },
  computed: {
    selection: {
      get() {
        return this.$store.state.selections.find(({ id }) => id === this.id)
      },
      // debounce to prevent race condition API calls with keyboard use
      set: debounce(function(option) {
        if (option !== undefined) {
          this.$store.dispatch("makeSelection", option)
        }
      }, 100),
    },
  },
  props: {
    id: { required: true, type: String },
    label: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
  },
}
</script>
